const therapists = [
  {
    route: 'annabella',
    name: 'Annabella Hagen',
    credentials: 'LCSW',
    role: 'Clinical Director and Founder',
    specialties: 'Scrupulosity OCD and other OCD themes including relationship, harm, and more!',
    specialtiesFull:
      'Scrupulosity OCD and other OCD themes including relationship, harm, existential, perfectionism, real events, and other sub-themes where the person\'s persevering "safety behaviors" might be internal (e.g., rumination, mental checking, time-traveling) compulsions.',
    clientPopulation: [
      'Experienced working with children, adolescents, adults, and families.',
      "Currently, I'm focused on working with young adults with scrupulosity OCD as well as young women and young men preparing to serve LDS missions or coming back early due to their challenges with scrupulosity and other OCD themes.",
      'Since 2000, I have worked with children, adolescents, and adults, who struggle with OCD, anxiety, trauma, depression, grief, behavioral and family challenges.',
    ],
    modalities:
      'Acceptance and Commitment Therapy (ACT) | Exposure and Response Prevention (ERP) | Inference-based Cognitive Behavioral Therapy (I-CBT) | Cognitive Behavioral Therapy (CBT) | Mindfulness | Trauma-Focused Cognitive Behavioral Therapy (TF-CBT)',
    education: [
      'Master of Social Work - Brigham Young University',
      'Bachelor of Science - Psychology - Brigham Young University',
      'Graduate of the Behavior Therapy Training Institute for OCD (BTTI) - 2012',
    ],
    other:
      'Committee Member of the Faith and OCD Council for the IOCDF | Member, IOCD Foundation | Member, ACBS (Association for Contextual Behavioral Science | Presenter, International OCD Foundation Conferences | Presenter and Trainer on topics related to ACT and the treatment for OCD, anxiety, and depression',
    image: 'v1680227558/annabella_new.png',
    metaDescription:
      "I founded Mindset Family Therapy in 2008 to better serve our community's need for specialized local expertise. We have experienced overwhelming success in treating OCD and OC related disorders. In my humble opinion, we (clinicians at Mindset Family Therapy) understand OCD and know how to address it!",
  },
  {
    route: 'jon',
    name: 'Jon Case',
    credentials: 'LCSW',
    specialties: 'OCD, ADHD, anxiety, depression, compulsive sexual behavior, and more!',
    specialtiesFull:
      "OCD, ADHD, anxiety disorders, depression/suicidal ideation/self-harm, compulsive sexual behavior, attachment disorders, tics and Tourette's, autism/nonverbal learning disorder/social pragmatic communication disorder.",
    clientPopulation: [
      'Experienced treating adolescents, young adults, and families, and my practice is LGBTQ friendly.',
    ],
    role: 'Senior Therapist',
    modalities:
      'Acceptance and Commitment Therapy (ACT) | Inference-based Cognitive Behavioral Therapy (I-CBT) | Social Thinking | Attachment Theory | Solution Focused | Exposure and Response Prevention (ERP) | Motivational Interviewing | Mindfulness | Parent Coaching',
    education: [
      'Master of Social Work - Brigham Young University',
      'Bachelor of Science - Psychology - Brigham Young University',
    ],
    other:
      'Member, ACBS (Association for Contextual Behavioral Science) | Member, IOCD Foundation | Co-Presenter, International OCD Foundation Annual Conference',
    image: 'v1634596900/jon_c.png',
    metaDescription:
      'I offer a gentle approach to therapy. I will support you in discovering your values and choosing a life for yourself that is resonant with your values. In that pursuit, we will develop skills together to help you stay in contact with the present moment, to be grounded and alert to your experiences, and increasingly resilient in the face of uncomfortable and difficult moments.',
  },
  {
    route: 'erik',
    name: 'Erik Wells',
    credentials: 'LCSW',
    role: 'Senior Therapist',
    specialties: "OCD, Trichotillomania, tics and Tourette's, ADHD, anxiety, depression, and more!",
    specialtiesFull:
      "OCD, Trichotillomania, tics and Tourette's, anxiety disorders, depression, ADHD, addiction (including compulsive sexual behavior), personality disorders, challenges associated with Autism, attachment disorders, and relational problems and others.",
    clientPopulation: [
      'Experienced treating adolescents and willing to see clients ages 12 and up.',
    ],
    modalities:
      'Acceptance and Commitment Therapy (ACT) | Exposure and Response Prevention (ERP) | Mindfulness | Inference-based Cognitive Behavioral Therapy (I-CBT) | Cognitive Behavioral Therapy (CBT) | Trauma Therapy | Motivational Interviewing | Attachment Theory | Trust-Based Relational Intervention (TBRI)',
    education: [
      'Master of Social Work - Brigham Young University',
      'Bachelor of Science - Spanish and Linguistics - Brigham Young University',
    ],
    other: 'Member, IOCD Foundation | Co-Presenter, International OCD Foundation Annual Conference',
    image: 'v1648599683/erik.png',
    metaDescription:
      'I enjoy the process of therapy and love hearing the unique stories of each individual I meet. I hope that as we work together you can gain a richer understanding of yourself and the world around you, connect more deeply with the people that you love, develop a greater sense of meaning and purpose in your life, and feel safe and comfortable just being you.',
  },
  {
    route: 'carrie',
    name: 'Carrie Wendt',
    credentials: 'LCSW',
    role: 'Therapist',
    specialties: 'OCD and OC related concerns, anxiety, depression, family challenges, and more!',
    specialtiesFull:
      'OCD and OC related disorders, anxiety disorders, grief, depression, trauma, depression, suicidal ideation/self-harm, and relationship challenges.',
    clientPopulation: ['Experienced treating children, adolescents, adults, and families.'],
    modalities:
      'Acceptance and Commitment Therapy (ACT) | Exposure and Response Prevention (ERP) | Inference-based Cognitive Behavioral Therapy (I-CBT) | Motivational Interviewing (MI) | Mindfulness | Trauma Therapy | Cognitive Behavioral Therapy (CBT) | Narrative Therapy',
    education: [
      'Master of Social Work - Brigham Young University',
      'Bachelor of Science - Psychology - Brigham Young University',
    ],
    other: 'Member, IOCD Foundation | Co-Presenter, International OCD Foundation Annual Conference',
    image: 'v1655771070/carrie_w_2.png',
    metaDescription:
      "I have witnessed healing in my experience working with children, adolescents, adults, and families in helping to navigate life's challenges and difficulties. I love this work and I welcome and am inclusive to all races, beliefs, socioeconomic statuses, disabilities, and LGBTQIA+ members.",
  },
  {
    route: 'christine',
    name: 'Christine Black',
    credentials: 'LCSW',
    role: 'Therapist',
    specialties: 'OCD (including scrupulosity), anxiety, postpartum depression, and more!',
    specialtiesFull:
      'OCD (including scrupulosity), OC related disorders, postpartum depression, anxiety disorders, trauma, grief, depression, suicidality, self-harm and LGBTQ related issues.',
    clientPopulation: [
      'Experienced treating people of all ages. Currently, only offering telehealth sessions which are recommended for ages 8 and up.',
    ],
    modalities:
      'Acceptance and Commitment Therapy (ACT) | Exposure and Response Prevention (ERP) | Internal Family systems (IFS) | Cognitive Behavioral Therapy (CBT) | Mindfulness | Play Therapy | Child Parent Relationship Training | Expressive Arts Therapy',
    education: [
      'Master of Social Work - Brigham Young University',
      'Bachelor of Science - Psychology - Brigham Young University',
    ],
    other: 'Member, IOCD Foundation | Co-Presenter International OCD Foundation Annual Conference',
    image: 'v1614128539/christine_b.png',
    metaDescription:
      "I love to hear people's unique stories and help them find healing from emotional wounds. I am inspired by the strength, power and resilience of the human soul. As a therapist, I aim to create a safe space where the client can find hope and healing through genuine acceptance.",
  },
  {
    route: 'brynlee',
    name: 'Brynlee Furnell',
    credentials: 'CSW',
    role: 'Therapist',
    specialties: 'OCD, anxiety, depression, trauma, grief, relational problems, and more!',
    specialtiesFull:
      'OCD and OC related disorders including Trichotillomania, anxiety disorders, depression, trauma, grief, depression, self-harm, suicidality, challenges associated with Autism, child behavioral challenges, and relational problems.',
    clientPopulation: [
      'Experienced working with children, adolescents, young adults, and families.',
    ],
    modalities:
      'Acceptance and Commitment Therapy (ACT) | Exposure and Response Prevention (ERP) | Trauma Therapy | Cognitive Behavioral Therapy (CBT) | Mind-Body Bridging (MBB) | Emotionally-Focused Therapy (EFT) | Internal Family Systems (IFS) | Accelerated Resolution Therapy (ART) | Sandtray Therapy | Art and other expressive interventions',
    education: [
      'Master of Social Work - Brigham Young University',
      'Bachelor of Science - Psychology - Southern Utah University',
    ],
    other: 'Member, IOCD Foundation',
    image: 'v1727388537/brynlee.png',
    metaDescription:
      'My experience includes working with adolescents, adults, couples, and families in both outpatient and residential treatment settings. I believe therapy is most effective when it is a collaborative process considerate of the unique needs, circumstances, cultural backgrounds, and strengths each client brings.',
  },
  {
    route: 'sarah',
    name: 'Sarah Ward',
    credentials: 'LCSW',
    role: 'Therapist',
    specialties: 'OCD, trauma, anxiety, depression, child behavioral challenges, and more!',
    specialtiesFull:
      'OCD, trauma, anxiety, depression, attachment concerns, grief, self-harm, and child behavioral challenges.',
    clientPopulation: ['Experienced working with children, adolescents, adults, and families.'],
    modalities:
      'Eye Movement Desensitization and Reprocessing (EMDR) | Acceptance and Commitment Therapy (ACT) | Exposure and Response Prevention (ERP) | Child-Centered Play Therapy (CCPT) | Child-Parent Relationship Therapy (CPRT) | Cognitive Behavioral Therapy (CBT) | Motivational Interviewing (MI) | Mindfulness',
    education: [
      'Master of Social Work - Brigham Young University',
      'Bachelor of Science - Psychology - Brigham Young University',
    ],
    image: 'v1734638782/sarah.png',
    metaDescription:
      'As a therapist, I am grateful for the opportunity to help individuals and families overcome challenges and develop a greater sense of their worth. I aim to create a safe and collaborative space where clients feel they can gain the strength and skills to heal and become their best selves.',
  },
  {
    route: 'rob',
    name: 'Rob Dixon',
    credentials: 'CMHC-Intern',
    role: 'Therapist',
    specialties: 'OCD, depression, anxiety, couples and family counseling, and more!',
    specialtiesFull:
      'OCD, depression, couples and family counseling, anxiety disorders, and other emotional and behavioral concerns.',
    clientPopulation: ['I enjoy working with adolescents, young adults, and families.'],
    modalities:
      'Family Systems Therapy (FST) | Person-Centered Therapy (PCT) | Acceptance and Commitment Therapy (ACT) | Exposure and Response Prevention (ERP)',
    education: [
      "Master's degree in Clinical Mental Health Counseling - Rocky Mountain University (2026)",
      'Bachelor of Science - Psychology - Brigham Young University - Graduated Magna Cum Laude',
    ],
    image: 'v1738199116/rob.png',
    metaDescription:
      "I firmly believe that change is possible for everyone. In my own life, I have experienced the value of a trusted witness share in my journey, and I'm humbled to be able to be that person for others. I hope to create an open and collaborative space where healing can come through self-discovery and insight. I believe the real answers come from the client, and strive to maintain that focus in my practice.",
  },
  {
    route: 'kayden',
    name: 'Kayden Barlow',
    credentials: 'MSW-Intern',
    role: 'Therapist',
    specialties: 'OCD, anxiety, depression, and more!',
    specialtiesFull: 'OCD, anxiety, depression, trauma, and related disorders.',
    clientPopulation: ['Passionate about working with adolescents, young adults, and adults.'],
    modalities:
      'Acceptance and Commitment Therapy (ACT) | Cognitive Behavioral Therapy (CBT) | Unified Protocol (UP) | Exposure and Response Prevention (ERP) | Inference-based Cognitive Behavioral Therapy (I-CBT)',
    education: [
      'Master of Social Work - Brigham Young University (2026)',
      'Bachelor of Science - Psychology - Brigham Young University',
    ],
    image: 'v1743735959/kayden.png',
    metaDescription:
      'I am committed to providing a compassionate and inclusive space where people of all races, orientations, identities, and beliefs feel seen and valued. I have worked in the mental health field for five years and have found deep fulfillment in supporting individuals on their journey toward healing. Through my work experience, I have learned that no one should be defined by their worst moments.',
  },
  {
    route: 'aleda',
    name: 'Aleda Smith',
    credentials: 'MSW-Intern',
    role: 'Therapist',
    specialties: 'OCD, anxiety, depression, trauma, and more!',
    specialtiesFull:
      'OCD, anxiety, depression, trauma, and other emotional and behavioral concerns.',
    clientPopulation: [
      'Passionate about working with kids, adolescents, young adults, adults and families.',
    ],
    modalities:
      'Acceptance and Commitment Therapy (ACT) | Cognitive Behavioral Therapy (CBT) | Exposure and Response Prevention (ERP) | Inference-based Cognitive Behavioral Therapy (I-CBT) | Play Therapy | Motivational Interviewing (MI) | Mindfulness',
    education: [
      'Master of Social Work - Brigham Young University (2026)',
      'Bachelor of Science - Psychology - Utah Valley University (2023)',
    ],
    image: 'v1743736421/aleda.png',
    metaDescription:
      "I believe that healing happens when people feel truly seen, heard, and supported. My goal as a therapist is to create a space where clients feel safe to explore their emotions, work through challenges, and build on their strengths. I approach therapy with warmth, curiosity, and a deep respect for each person's unique experiences.",
  },
  {
    route: 'dylan',
    name: 'Dylan Manzanares',
    credentials: 'CSW',
    role: 'Therapist',
    specialties: 'OCD, anxiety, depression, compulsive sexual behaviors, and more!',
    specialtiesFull:
      'OCD and OC related disorders, compulsive sexual behaviors, anxiety disorders, depression, trauma, relational issues, suicidal thoughts, and psychosis.',
    clientPopulation: [
      'Experienced working with adolescents, college students, adults, and couples.',
    ],
    modalities:
      'Acceptance and Commitment Therapy (ACT) | Exposure and Response Prevention (ERP) |  Cognitive Behavioral Therapy (CBT) | Motivational Interviewing (MI) | Mindfulness | Self-Compassion | Narrative Therapy | Trauma Certification',
    education1: 'Master of Social Work — Brigham Young University',
    education2: 'Bachelor of Science - Psychology - Southern Utah University',
    education: [
      'Master of Social Work - Brigham Young University',
      'Bachelor of Science - Psychology - Southern Utah University',
    ],
    image: 'v1743736754/dylan.png',
    metaDescription:
      'People are more than their problems. I believe everyone has unique strengths that, when developed, can lead to a rich life filled with meaning and joy.',
  },
];

module.exports = therapists;
