import _ from 'lodash';
import React from 'react';
import PageHeader from '../PageHeader';
import Therapist from './components/Therapist';
import ShortBio from './components/ShortBio';
import FullBio from './components/FullBio';
import therapists from '../../data/therapists';

import styles from './team.module.scss';

const Team = () => {
  const background =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1497108283/headerBackgroundTeam_juwgw4.jpg';
  const statement =
    'Our team of experienced therapists provide knowledgeable, empathetic, and personalized care for you or your loved one.';

  const therapistList = _.map(therapists, (therapist, index) => {
    const backgroundColor = index % 2 === 0 ? '#EFE7DF' : '#F4F8F9';
    const image = `https://res.cloudinary.com/dewd4pral/image/upload/q_60,c_scale,h_200,w_200/${therapist.image}`;

    const shortBio = <ShortBio name={therapist.route} />;
    const fullBio = <FullBio name={therapist.route} />;

    return (
      <Therapist
        key={index}
        name={therapist.name}
        credentials={therapist.credentials}
        role={therapist.role}
        specialties={therapist.specialties}
        specialtiesFull={therapist.specialtiesFull}
        image={image}
        clientPopulation={therapist.clientPopulation}
        modalities={therapist.modalities}
        education={therapist.education}
        other={therapist.other}
        shortBio={shortBio}
        fullBio={fullBio}
        useFullBio={false}
        backgroundColor={backgroundColor}
      />
    );
  });

  return (
    <main>
      <PageHeader pageCategory="About" pageName="Our Team" headerImage={background} />
      <div className={styles.statementContainer}>
        <div className={styles.teamStatement}>
          <p className={styles.statement}>{statement}</p>
        </div>
      </div>
      {therapistList}
    </main>
  );
};

export default Team;
